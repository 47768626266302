import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [isCompleted, setIsCompleted] = useState(false);
  const [signUrl, setSignUrl] = useState('');

  useEffect(() => {
    const fetchSignLink = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const documentId = urlParams.get('documentId');
      const email = urlParams.get('email').replace(/ /g, '+');

      if (documentId && email) {
        try {
          const response = await fetch(`https://flutterflow-boldsign-proxy.roofquotes.io/v1/document/getEmbeddedSignLink?documentId=${documentId}&signerEmail=${encodeURIComponent(email)}`);
          const data = await response.json();
          if (data.signLink) {
            setSignUrl(data.signLink);
          } else {
            console.error('signLink not found in response');
          }
        } catch (error) {
          console.error('Error fetching signLink:', error);
        }
      } else {
        console.error('documentId or email parameter is missing from the URL');
      }
    };

    fetchSignLink();

    const messageListener = (params) => {
      console.log('Received message:', params);
      console.log('Message origin:', params.origin);
      console.log('Message data:', params.data);

      if (typeof params.data === 'object' && params.data.action) {
        switch (params.data.action) {
          case "onDraftSuccess":
            // handle draft success
            console.log('Draft saved successfully');
            break;
          case "onDraftFailed":
            // handle draft failure
            console.error('Failed to save draft');
            break;
          case "onCreateSuccess":
            // handle create success
            console.log('Document created successfully');
            handleDocumentCompletion();
            break;
          case "onCreateFailed":
            // handle create failure
            console.error('Failed to create document');
            break;
          case "onDocumentSigned":
            // handle document signed
            console.log('Document signed successfully');
            handleDocumentCompletion();
            break;
          default:
            console.warn('Unhandled message:', params.data);
            break;
        }
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  const handleDocumentCompletion = () => {
    setIsCompleted(true);
    setTimeout(() => {
      window.location.href = "https://homeowners.roofquotes.com";
    }, 3000);
  };

  return (
    <div className="App">
      {!isCompleted && (
        <iframe
          id="prepare_page"
          title="BoldSign Document"
          src={signUrl}
          className="frame"
        ></iframe>
      )}
      {isCompleted && (
        <div id="completion-message" className="completion-message">
          Completed. Redirecting you to the next task
        </div>
      )}
    </div>
  );
}

export default App;